<template>
  <div class="mx-auto max-w-6xl text-white">
    <h1 class="text-3xl text-white mt-4">VueNome Patient Report</h1>
    <!-- Patient Summary -->
    <div class="flex my-4 text-left">
      <div class="w-1/2 mr-4 p-4 bg-bgGrayLight rounded">
        <h2 class="text-xl font-semibold mb-4">Patient Information</h2>
        <p><b>Patient Name:</b> John Smith</p>
        <p><b>Other Name:</b> N/A</p>
        <p><b>Med. Rec #:</b> 123456789</p>
        <p><b>DOB:</b> 9/12/1974</p>
        <p><b>Gender:</b> Male</p>
        <p><b>Physician(s):</b> Dr. Shelley</p>
      </div>
      <div class="w-1/2 p-4 bg-bgGrayLight rounded">
        <h2 class="text-xl font-semibold mb-4">Diagnosis</h2>
        <p>POSITIVE FOR SOMATIC CANCER-ASSOCIATED</p>
        <p>VARIATION POSITIVE FOR COPY NUMBER VARIATION AND/OR LOSS OF HETEROZYGOSITY</p>
        <p>NEGATIVE FOR GERMLINE CANCER-ASSOCIATED VARIATION</p>
        <br />
        <p><b>TEST ORDERED:</b> Somatic Disease/Germline Comparator Exome</p>
        <p><b>Indications for Study:</b> Pineoblastoma</p>
      </div>
    </div>

    <!-- Variants Charts and Annotations -->
    <div class="flex text-white text-left max-h-80vh">
      <div class="w-1/2 flex flex-col mr-4 p-4 bg-bgGrayLight rounded overflow-auto">
        <h2 class="text-xl font-semibold mb-4">Variants Summary</h2>
        <div class="mb-8">
          <Variants2dChart />
        </div>
        <div>
          <Variants3dPlot @point-hovered="setHoveredData" />
        </div>
      </div>

      <div class="w-1/2 min-w-1/2 p-4 bg-bgGrayLight rounded overflow-auto">
        <h2 class="text-xl font-semibold mb-4">Interpretation</h2>

        <div class="flex mb-4">
          <div class="w-1/3 bg-bgGrayLighter mr-2 p-4 text-center rounded">
            <p class="text-3xl">{{ hoveredData.y }}</p>
            <p>Diagnostic Impact</p>
          </div>
          <div class="w-1/3 bg-bgGrayLighter mr-2 p-4 text-center rounded">
            <p class="text-3xl">{{ hoveredData.z }}</p>
            <p>Therapeutic Impact</p>
          </div>
          <div class="w-1/3 bg-bgGrayLighter p-4 text-center rounded">
            <p class="text-3xl">{{ hoveredData.x }}</p>
            <p>Prognostic Impact</p>
          </div>
        </div>

        <p v-html="hoveredAnnotation"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Variants2dChart from '@/components/Variants2dChart.vue'
import Variants3dPlot from '@/components/Variants3dPlot.vue'

export default {
  name: 'PatientReport',
  components: {
    Variants2dChart,
    Variants3dPlot,
  },
  setup() {
    let hoveredData = ref({
      customdata:
        '<h2 class="text-xl text-center p-10">Select a variant from the charts at the left, and its details will appear here</h2>',
    })

    function setHoveredData(data) {
      console.log('DATA:', data)
      hoveredData.value = data
    }

    const hoveredAnnotation = computed(() => {
      return hoveredData.value && hoveredData.value.customdata.replace('\n', '<br />')
    })

    return {
      hoveredData,
      setHoveredData,
      hoveredAnnotation,
    }
  },
}
</script>
