<template>
  <div>
    <table class="table-fixed">
      <thead class="text-white">
        <tr>
          <th></th>
          <th>Tier I (A)</th>
          <th>Tier I (B)</th>
          <th>Tier II (A)</th>
          <th>Tier II (B)</th>
          <th>Tier II (C)</th>
        </tr>
      </thead>
      <tbody class="text-right text-white">
        <tr>
          <td class="">Mutations</td>
          <td>
            {{
              mutations
                .map((m) => m.tier === 'TIER_1_A' && '🔴')
                .filter((i) => i != false)
                .join(' ')
            }}
          </td>
          <td>
            {{
              mutations
                .map((m) => m.tier === 'TIER_1_B' && '🟠')
                .filter((i) => i != false)
                .join(' ')
            }}
          </td>
          <td></td>
          <td></td>
          <td>
            {{
              mutations
                .map((m) => m.tier === 'TIER_1_B' && '🔵')
                .filter((i) => i != false)
                .join(' ')
            }}
          </td>
        </tr>
        <tr>
          <td>Chromosomal Aberrations</td>
        </tr>
        <tr>
          <td>Amplificiation</td>
        </tr>
        <tr>
          <td>Transcriptomics</td>
        </tr>
        <tr>
          <td>Biomarkers</td>
        </tr>
        <tr>
          <td>Methylation Class</td>
        </tr>
        <tr>
          <td>Proteomics</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue'

export default {
  setup() {
    const d3 = inject('d3')
    const Plotly = inject('Plotly')

    const mutations = ref([])

    d3.csv('https://assets.codepen.io/16470/mutations-for-plot.csv', function (rows) {
      console.log('rows_1', rows)

      const plot = document.getElementById('3DPlot')
      const PROG_KEY = 'prognostic_impact'
      const DIAG_KEY = 'diagnostic_impact'
      const THER_KEY = 'therapeutic_impact'
      const TIER_KEY = 'tier'

      //
      // PREP THE DATA
      //
      const unpack = (rows, key) => rows.map((row) => row[key])

      const getCustomData = (rows) => {
        const annotations = unpack(rows, 'Annotation')
        const summaries = unpack(rows, 'ai_summary')

        const formatAnnotation = (annotation, summary) => {
          const result =
            '<h2 class="mb-4 text-l font-semibold">AI Summary</h2>' +
            '<p class="mb-4">' +
            summary +
            '</p>'

          const lines = annotation.split('\n')
          const formatted = lines.map((line) => `<p class="mb-4">${line}</p>`).join('')
          return (
            result +
            '<hr class="mb-4"><h2 class="mb-4 text-l font-semibold">Full Annotation</h2><p>' +
            formatted
          )
        }

        const results = []
        for (let i = 0; i < annotations.length; i++) {
          results[i] = formatAnnotation(annotations[i], summaries[i])
        }

        return results
      }

      // TODO: Refactor to common function to use across tiers
      const tier1ARows = rows.filter((row) => row[TIER_KEY].includes('TIER_1_A'))
      var tier1A = {
        x: unpack(tier1ARows, PROG_KEY),
        y: unpack(tier1ARows, DIAG_KEY),
        z: unpack(tier1ARows, THER_KEY),
        customdata: getCustomData(tier1ARows),
        mode: 'markers',
        name: 'Tier I (A)',
        marker: {
          size: 8,
          color: 'rgba(217, 0, 0, 1)',
          opacity: 0.8,
        },
        type: 'scatter3d',
        hoverinfo: 'none',
      }

      const tier1BRows = rows.filter((row) => row[TIER_KEY].includes('TIER_1_B'))
      var tier1B = {
        x: unpack(tier1BRows, PROG_KEY),
        y: unpack(tier1BRows, DIAG_KEY),
        z: unpack(tier1BRows, THER_KEY),
        customdata: getCustomData(tier1BRows),
        mode: 'markers',
        name: 'Tier I (B)',
        marker: {
          size: 8,
          color: 'rgba(255, 165, 0, 1)',
          opacity: 0.8,
        },
        type: 'scatter3d',
        hoverinfo: 'none',
      }

      const tier2CRows = rows.filter((row) => row[TIER_KEY].includes('TIER_2_C'))
      var tier2C = {
        x: unpack(tier2CRows, PROG_KEY),
        y: unpack(tier2CRows, DIAG_KEY),
        z: unpack(tier2CRows, THER_KEY),
        customdata: getCustomData(tier2CRows),
        mode: 'markers',
        name: 'Tier II (C)',
        marker: {
          size: 8,
          color: 'rgba(100, 100, 255, 1)',
          opacity: 0.8,
        },
        type: 'scatter3d',
        hoverinfo: 'none',
      }

      mutations.value = [...tier1ARows, ...tier1BRows, ...tier2CRows]
      console.log('mutations.value', mutations.value)
      const data = [tier1A, tier1B, tier2C]

      // TODO: With CSV data now unwrapped, render as a 2D Chart
    })

    return {
      mutations,
    }
  },
}
</script>

<style scoped>
.mobile-link {
  @apply block cursor-pointer border-l-4 px-4 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800;
}

.mobile-link--main {
  @apply block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700;
}
</style>
