<template>
  <PatientReport />
</template>

<script>
import PatientReport from '@/views/PatientReport.vue'

export default {
  name: 'Home',
  components: {
    PatientReport,
  },
  setup() {},
}
</script>
